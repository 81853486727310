var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as uniq from 'lodash.uniq';
import { createProduct, updateProductCurrencyFromSettings } from '../api';
import { APP_ID, createDefaultProduct, createSettingsPanelConfig, panelHelpId } from '../constants';
import { editorReady } from './editorReady';
import { openConnectPaymentMethodsPanel, openSettingsPanel } from './panels';
import { getAppManifest, getButtonText, updateButtonLabel } from './utils';
import { redefineButtonProductId } from './utils/duplicateCase';
import { overrideConnectionConfig } from './utils/overrideConnectionConfig';
import { getProductIdsList } from './utils/getProductIdsList';
import translations from '../utils/translations';
import { logger, getBiLoggerParams } from '../utils/logger';
import { EditorEvent } from '../enums/editorEvents';
import { defaultSentryConfig } from '../utils/sentry';
import { createHandleError, defaultErrorHandler, handleSdkNotInitialized, } from '../utils/handleError';
import { EditorInteractionName } from '../enums/EditorInteractionName';
var handleError = defaultErrorHandler;
var _editorSDK;
var _appDefinitionId = '';
export var _fedopsLogger = {
    interactionStarted: function (name) { return name; },
    interactionEnded: function (name) { return name; },
    appLoadStarted: function () { return undefined; },
    appLoaded: function () { return undefined; },
};
export var onEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, eventPayload, componentRef, _b, params, savedProductId, instance, buttonLabel, settings, e_1, currency, metaSiteId, instance, resp, productId, e_2;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = event.eventPayload, eventPayload = _a === void 0 ? {} : _a;
                componentRef = eventPayload.componentRef || eventPayload.compRef;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 12, , 13]);
                _b = eventPayload.id;
                switch (_b) {
                    case EditorEvent.ConnectPaymentMethods: return [3 /*break*/, 2];
                    case EditorEvent.OpenPaymentSettings: return [3 /*break*/, 3];
                }
                return [3 /*break*/, 10];
            case 2:
                {
                    _fedopsLogger.interactionStarted(EditorInteractionName.OPEN_CONNECT_PAYMENT_METHODS_MODAL);
                    if (!_editorSDK) {
                        handleSdkNotInitialized();
                        return [2 /*return*/];
                    }
                    openConnectPaymentMethodsPanel({ editorSDK: _editorSDK, appDefinitionId: _appDefinitionId, startLoadTime: Date.now() });
                    return [3 /*break*/, 11];
                }
                _c.label = 3;
            case 3:
                _fedopsLogger.interactionStarted(EditorInteractionName.PAY_BUTTON_OPEN_SETTINGS);
                if (!_editorSDK) {
                    handleSdkNotInitialized();
                    return [2 /*return*/];
                }
                return [4 /*yield*/, getBiLoggerParams(_editorSDK, componentRef.id)];
            case 4:
                params = _c.sent();
                logger.payButtonClickToOpenPaymentSettings(params);
                return [4 /*yield*/, _editorSDK.document.controllers.getData(_appDefinitionId, { controllerRef: componentRef })];
            case 5:
                savedProductId = (_c.sent()).config.productId;
                return [4 /*yield*/, redefineButtonProductId({
                        editorSDK: _editorSDK,
                        appDefinitionId: _appDefinitionId,
                        controllerRef: componentRef,
                        savedProductId: savedProductId,
                    }, handleError)];
            case 6:
                savedProductId = _c.sent();
                return [4 /*yield*/, _editorSDK.info.getAppInstance(_appDefinitionId)];
            case 7:
                instance = _c.sent();
                return [4 /*yield*/, updateProductCurrencyFromSettings(instance, savedProductId)];
            case 8:
                _c.sent();
                return [4 /*yield*/, getButtonText(_editorSDK, _appDefinitionId, componentRef)];
            case 9:
                buttonLabel = (_c.sent()).label;
                settings = createSettingsPanelConfig({
                    settingsTitle: translations.t('settingsPanel.title'),
                    startLoadTime: Date.now(),
                    appDefinitionId: _appDefinitionId,
                    buttonLabel: buttonLabel,
                    componentRef: componentRef,
                    productId: savedProductId,
                    helpId: panelHelpId,
                    instance: instance,
                });
                _editorSDK.editor.openComponentPanel('settings-panel', settings);
                return [3 /*break*/, 11];
            case 10:
                {
                    return [3 /*break*/, 11];
                }
                _c.label = 11;
            case 11: return [3 /*break*/, 13];
            case 12:
                e_1 = _c.sent();
                handleError(e_1);
                return [3 /*break*/, 13];
            case 13:
                if (!(event.eventType === 'componentAddedToStage')) return [3 /*break*/, 23];
                _c.label = 14;
            case 14:
                _c.trys.push([14, 20, , 21]);
                return [4 /*yield*/, _editorSDK.document.info.getCurrency(_appDefinitionId)];
            case 15:
                currency = _c.sent();
                return [4 /*yield*/, _editorSDK.document.info.getMetaSiteId()];
            case 16:
                metaSiteId = _c.sent();
                return [4 /*yield*/, _editorSDK.info.getAppInstance(APP_ID)];
            case 17:
                instance = _c.sent();
                return [4 /*yield*/, createProduct(instance, createDefaultProduct(currency, translations.t('item.defaultName')))];
            case 18:
                resp = _c.sent();
                productId = resp.productId;
                return [4 /*yield*/, overrideConnectionConfig(_editorSDK, _appDefinitionId, componentRef, { productId: productId, metaSiteId: metaSiteId }, handleError)];
            case 19:
                _c.sent();
                return [3 /*break*/, 21];
            case 20:
                e_2 = _c.sent();
                handleError(e_2, 'Error while creating a product.');
                return [3 /*break*/, 21];
            case 21: return [4 /*yield*/, _editorSDK.document.save()];
            case 22:
                _c.sent();
                _c.label = 23;
            case 23:
                if (!(event.eventType === 'componentDeleted')) return [3 /*break*/, 25];
                return [4 /*yield*/, _editorSDK.document.save()];
            case 24:
                _c.sent();
                _c.label = 25;
            case 25: return [2 /*return*/];
        }
    });
}); };
var initializeFedops = function (monitoring) {
    try {
        var fedopsLogger = monitoring.createFedopsLogger();
        _fedopsLogger = fedopsLogger('pay-button');
        _fedopsLogger.interactionStarted(EditorInteractionName.ALE);
    }
    catch (e) {
        handleError(e);
    }
};
var overrideEditorSDKAndAppDefId = function (editorSDK, appDefinitionId) {
    _editorSDK = editorSDK;
    _appDefinitionId = appDefinitionId;
};
var initErrorReporter = function (monitoring, editorSDK) { return __awaiter(void 0, void 0, void 0, function () {
    var userId, locale, msId, tags_1, sentryInstance, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, editorSDK.document.info.getUserId()];
            case 1:
                userId = _a.sent();
                return [4 /*yield*/, editorSDK.document.info.getSiteRegion()];
            case 2:
                locale = _a.sent();
                return [4 /*yield*/, editorSDK.document.info.getMetaSiteId()];
            case 3:
                msId = _a.sent();
                tags_1 = {
                    msId: msId,
                    userId: userId,
                    locale: locale,
                };
                sentryInstance = monitoring.createSentryMonitorForApp(defaultSentryConfig.dsn, {
                    dataCallback: function () { return (__assign(__assign({}, defaultSentryConfig.config), { tags: __assign({}, tags_1) })); }
                });
                handleError = createHandleError(sentryInstance.captureException.bind(sentryInstance));
                return [3 /*break*/, 5];
            case 4:
                e_3 = _a.sent();
                // tslint:disable-next-line:no-console
                console.error('Sentry is not initialized', e_3);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
export default {
    exports: {
        overrideConnectionConfig: function (controllerRef, connectionConfig) { return overrideConnectionConfig(_editorSDK, _appDefinitionId, controllerRef, connectionConfig, handleError); },
        updateButtonLabel: function (componentRef, label) { return updateButtonLabel(_editorSDK, _appDefinitionId, componentRef, label, handleError); },
        openSettingsPanel: function (appDefId) { return __awaiter(void 0, void 0, void 0, function () {
            var instance_1, productIds, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        // https://wix.slack.com/archives/C4KPAQ33K/p1542103124049300
                        return [4 /*yield*/, openSettingsPanel(_editorSDK, appDefId)];
                    case 1:
                        // https://wix.slack.com/archives/C4KPAQ33K/p1542103124049300
                        _a.sent();
                        return [4 /*yield*/, _editorSDK.info.getAppInstance(APP_ID)];
                    case 2:
                        instance_1 = _a.sent();
                        return [4 /*yield*/, getProductIdsList(_editorSDK)];
                    case 3:
                        productIds = _a.sent();
                        uniq(productIds).forEach(function (id) { return updateProductCurrencyFromSettings(instance_1, id); });
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        handleError(e_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    },
    onEvent: onEvent,
    editorReady: function (editorSDK, appDefinitionId, _a) {
        var firstInstall = _a.firstInstall, origin = _a.origin, monitoring = _a.monitoring;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, initErrorReporter(monitoring, editorSDK)];
                    case 1:
                        _b.sent();
                        overrideEditorSDKAndAppDefId(editorSDK, appDefinitionId);
                        initializeFedops(monitoring);
                        return [2 /*return*/, editorReady(editorSDK, appDefinitionId, { firstInstall: firstInstall, origin: origin }, handleError)];
                }
            });
        });
    },
    getAppManifest: function () {
        return getAppManifest(_editorSDK);
    },
};
