import { NO_PAYMENTS_POP_UP_LINK, POP_UP_LINK } from '../constants';
export var getPremiumPageLink = function (metaSiteId) {
    return "https://www.wix.com/store/plans?siteGuid=" + metaSiteId + "&referralAdditionalInfo=payButtonSettingsButton&businessTab=true";
};
export var getSettingsLink = function (metaSiteId, appDefId) {
    return "https://www.wix.com/my-account/settings?metaSiteId=" + metaSiteId + "&dashboardPath=%2Fsettings%3FapplicationId%3D3991&appDefinitionId=" + appDefId + "&referralAdditionalInfo=PayButton";
};
export var getUpgradeAndTestPaymentModel = function (instance) {
    return POP_UP_LINK + "?instance=" + instance + "&referralInfo=payApiPaymentsPopup&startLoadTime=" + Date.now();
};
export var getPremiumStatusLink = function (baseUrl) {
    return baseUrl + "/cashier-settings-server/pay-button/premiumStatus";
};
export var getBusinessDataLink = function () {
    return "https://editor.wix.com/_api/site-properties-service/properties?fields.paths=paymentCurrency";
};
export var getNoPaymentsPopupUrl = function (_a) {
    var instance = _a.instance, referralInfo = _a.referralInfo, startLoadTime = _a.startLoadTime;
    return NO_PAYMENTS_POP_UP_LINK + "?instance=" + instance + "&referralInfo=" + referralInfo + "&startLoadTime=" + startLoadTime;
};
